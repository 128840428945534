<template>
  <div class="column is-centered mx-0 my-0">

    <div v-if="IsStreaming">
      <BasePlayer :options="videoOptions" :videoSource="videoSource" />
    </div>

    <div v-else style="position: relative">  
           
      <b-carousel
      animated="slide"
      :arrow="false"     
      :indicator-inside="false" 
      indicator-custom 
      :autoplay="false">
        <b-carousel-item 
        v-for="p in presets" 
        :key="p">
          <b-image
            class="image cameraimg image is-16by9 is-fullwidth" oncontextmenu="return false;"
            id="frame"
          :src="GetImageUrl"/>
        </b-carousel-item>
        <template #indicators="props" 
            >
            <b-image 
            class="al image" oncontextmenu="return false;"
            @click.native="SetPresetSelected(presets[props.i])"
            :src="GetCarouselImgs[presets[props.i]]">
          </b-image>
        </template>
      
      </b-carousel>
      
    </div>
  </div>

  <!-- <figure
        class="cameraimg image is-16by9 is-fullwidth mr-4 ml-4"
        oncontextmenu="return false;"
      >
        <img id="frame" class="image" :src="GetImageUrl" />
      </figure> -->
  <!-- MOVED TO GRIDCAMERAINFO
        <b-button
          @click="downloadImage"
          class="btn-high-left"
          icon-left="download"  
          type="is-primary"
          rounded
          inverted
          outlined
          size="is-small"
        />
        <b-switch
        passive-type="is-danger"
        left-label
        v-model="irMode"
        size="is-small"
        type="is-success"
        class="mt-2"
        >IR:</b-switch
        >
        <span v-if="irMode">Ativo</span> <span v-else>Inativo</span> 
      end of moved snippet -->
</template>
<style lang="scss" scoped>
  .is-active .al img {
      border:1px solid  red;
    }
    .cameraimg {
      width: 1280px;
      outline: none;
      border: none;
    }

    .btn-high-left {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .btn {
      border-radius: 6px;
      font-weight: bolder;
      font-size: 1.8vh;
    }

    .has-vertically-aligned-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .has-side-margins {
      margin-left: 3.25rem;
  }
</style>
<script>
import firebase from "../../../firebase.config.js";
import BasePlayer from "@/components/home/BasePlayer.vue";
export default {
  name: "GridCamera",
  data() {
    return {
      isListening: false,
      isFrameLoading: true,
      isFullPage: false,
      ImageUrl: "",
      IsStreaming: false,
      LastImageTimestamp: 0,
      videoOptions: {
        autoplay: true,
        controls: true,
        preload: true,
        inactivityTimeout: 0,
        liveui: true,
        fluid: true,
        sources: null,
      },
      videoSource: "",
      streamingData: {
        email: "",
        user: "",
        startTime: "",
        endTime: "",
        totalTime: "",
      },

      usersCompany: [],
      stateStream: true,
      imgCarousel:{}
    };
  },
  props: {
    Site: Object,
    presets: Array,
    presetSelected: Number,
  },
  components: {
    BasePlayer,
  },
  computed: {
    GetImageUrl() {
      return this.ImageUrl;
    },
    GetCarouselImgs() {
      return this.imgCarousel;
    },
    
    GetSites() {
      return this.$store.getters["database/GetSites"];
    },
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    // GetIr() {
    //   return this.irMode;
    // },
    firebaseStreamingPath() {
      return `mordor-sites/${this.Site[".key"]}/streaming`;
    },
  },
  watch: {
    Site: {
      handler(site) {
        if (
          !site.kinesisVideoService &&
          this.LastImageTimestamp !== site.status.frameSentFromMordor
        ) {
          this.LastImageTimestamp = site.status.frameSentFromMordor;
          this.GetMordorStatusImage();
        }
      },
    },
  },
  methods: {
   
    getImgUrl(value) {  //PLACEHOLDER IMAGE METHOD! REMOVE IN PRODUCTION
      return `https://fakeimg.pl/1280x720/?text=${value}`;
    },
    async GetMordorStatusImage() {
      try {
        this.irMode=localStorage.getItem("irMode")==="true"?true:false;
        const imageType = this.irMode ? "IR" : "TV";
        this.LastImageTimestamp = this.Site.status.frameSentFromMordor;
        this.isFrameLoading = true;
        const response = await this.$store.dispatch(
          `storage/GetMordorDemandImageDownloadLink${imageType}`,
          this.Site[".key"]
        );
        this.ImageUrl = response;        
      } catch (error) {
        console.log(error);
      } finally {
        this.isFrameLoading = false;
      }
    },
    async GetImageUrlCarousel() {
      await this.$store.dispatch(
          `storage/GetMordorDemandImageDownloadLinkCarousel`,
          {company:this.Site[".key"],presets:this.presets}).then((el) => {        
            this.imgCarousel=el;                 
          });

    },
    RequestStreaming() {
      if (this.IsStreaming) {
        this.CloseStreaming();
      } else {
        this.OpenStreaming();
      }
    },
    async OpenStreaming() {
      await this.IncrementUserWatchingCount();
      await this.AddUserFromWatching();
      this.SetStreamingOnDisconnectHandler();
      this.awaitForStream();
    },
    async awaitForStream() {
      const refPath = `mordor-sites/${this.Site[".key"]}/status/kinesisVideoService`;
      const ref = firebase.database().ref(refPath);
      const listeningTimeoutMs = 60000;

      const handleTimeout = () => {
        ref.off();
        this.LaunchToast("Timeout ao tentar abrir streaming.", "is-danger");
        this.emitSetIsStreamingToFalse();
        this.CloseStreaming();
      };

      const listeningTimeout = setTimeout(handleTimeout, listeningTimeoutMs);

      try {
        ref.on("value", (snap) => {
          if (snap.val()) {
            clearTimeout(listeningTimeout);
            ref.off();
            this.GetHLSStreaming();
          }
        });
      } catch (error) {
        clearTimeout(listeningTimeout);
        ref.off();
        this.LaunchToast(
          "Não foi possível conectar-se a transmissão.",
          "is-danger"
        );
        this.CloseStreaming();
      }
    },
    AddUserFromWatching() {
      return this.$store.dispatch("database/SetOnDatabase", {
        path:
          "mordor-sites/" +
          this.Site[".key"] +
          "/watching/" +
          this.GetCurrentUser.uid,
        object: this.GetCurrentUser.email,
      });
    },
    RemoveUserFromWatching() {
      return this.$store.dispatch("database/RemoveFromDatabase", {
        path:
          "mordor-sites/" +
          this.Site[".key"] +
          "/watching/" +
          this.GetCurrentUser.uid,
      });
    },
    GetUserWatchingCount() {
      return this.$store.dispatch("database/GetFromDatabase", {
        path: this.firebaseStreamingPath,
        returnSnapshotVal: false,
      });
    },
    IncrementUserWatchingCount() {
      return this.$store.dispatch("database/SetOnDatabase", {
        path: this.firebaseStreamingPath,
        object: firebase.database.ServerValue.increment(1),
      });
    },
    DecrementUserWatchingCount() {
      return this.$store.dispatch("database/SetOnDatabase", {
        path: this.firebaseStreamingPath,
        object: firebase.database.ServerValue.increment(-1),
      });
    },
    GetKinesisVideoUrl() {
      return firebase.functions().httpsCallable("getKinesisVideoUrl");
    },
    async CloseStreaming() {
      try {
        if (this.usersCompany.includes(this.GetCurrentUser.email)) {
          this.TimeCounterStreaming();
        }
        await this.RemoveUserFromWatching();
        const UserWatchingCount = await this.GetUserWatchingCount();

        if (UserWatchingCount.val() > 0) {
          await this.DecrementUserWatchingCount();
          this.CancelStreamingOnDisconnectHandler();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.IsStreaming = false;
        this.emitSetStreamingButtonLoadingToFalse();
        this.emitSetIsStreamingToFalse();
      }
    },
    async GetHLSStreaming() {
      try {
        const kinesisVideoUrl = this.GetKinesisVideoUrl();
        const streamingUrl = await kinesisVideoUrl({
          streamName: this.Site[".key"],
        });

        this.videoSource = streamingUrl.data.HLSStreamingSessionURL;
        if (this.videoSource == null && this.stateStream) {
          setTimeout(() => {
            this.GetHLSStreaming();
          }, 1000);
        } else {
          this.streamingData.startTime = Date.now();
          this.IsStreaming = true;
          this.emitSetStreamingButtonLoadingToFalse();
        }
      } catch (error) {
        console.log(error);
        this.LaunchToast("Não há streamings pra essa operação.", "is-warning");
      }
    },
    SetStreaming() {
      this.stateStream = !this.stateStream;
      this.CloseStreaming();
    },
    SetStreamingOnDisconnectHandler() {
      this.$store
        .dispatch("database/SetOnDisconnectHandler", {
          method: "remove",
          path:
            "mordor-sites/" +
            this.Site[".key"] +
            "/watching/" +
            this.GetCurrentUser.uid,
        })
        .then(() => {
          this.$store
            .dispatch("database/SetOnDisconnectHandler", {
              method: "set",
              path: this.firebaseStreamingPath,
              object: firebase.database.ServerValue.increment(-1),
            })
            .then(() => {
              if (this.usersCompany.includes(this.GetCurrentUser.email)) {
                const data = new Date(this.streamingData.startTime)
                  .toLocaleDateString("pt-br", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .split("/");
                this.streamingData.endTime =
                  firebase.database.ServerValue.TIMESTAMP;
                this.streamingData.totalTime = "";
                this.$store.dispatch("database/SetOnDisconnectHandler", {
                  method: "set",
                  path:
                    "mordor-history/" +
                    this.Site[".key"] +
                    "/streamingUsed/" +
                    data[2] +
                    "/" +
                    data[1] +
                    "/" +
                    this.streamingData.startTime,
                  object: this.streamingData,
                });
              }
            });
        });
    },
    CancelStreamingOnDisconnectHandler() {
      this.$store
        .dispatch("database/SetOnDisconnectHandler", {
          method: "cancel",
          path:
            "mordor-sites/" +
            this.Site[".key"] +
            "/watching/" +
            this.GetCurrentUser.uid,
        })
        .then(() => {
          this.$store
            .dispatch("database/SetOnDisconnectHandler", {
              method: "cancel",
              path: this.firebaseStreamingPath,
            })
            .then(() => {
              const data = new Date(this.streamingData.startTime)
                .toLocaleDateString("pt-br", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                .split("/");
              this.$store.dispatch("database/SetOnDisconnectHandler", {
                path:
                  "mordor-history/" +
                  this.Site[".key"] +
                  "/streamingUsed/" +
                  data[2] +
                  "/" +
                  data[1] +
                  "/" +
                  this.streamingData.startTime,
                method: "cancel",
              });
            });
        });
    },
    TimeCounterStreaming() {
      this.streamingData.endTime = Date.now();
      this.streamingData.totalTime =
        this.streamingData.endTime - this.streamingData.startTime;
      const data = new Date(this.streamingData.startTime)
        .toLocaleDateString("pt-br", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/");
      this.$store
        .dispatch("database/SetOnDatabase", {
          path:
            "mordor-history/" +
            this.Site[".key"] +
            "/streamingUsed/" +
            data[2] +
            "/" +
            data[1] +
            "/" +
            this.streamingData.startTime,
          object: this.streamingData,
        })
        .then(() => {
          this.CancelStreamingOnDisconnectHandler();
        });
    },
    LaunchToast(message, type) {
      this.$buefy.toast.open({
        //Displays toast with given properties below.
        message: message, //Message to display.
        type: type, //Toast's color.
        position: "is-bottom", //Toast's position.
        duration: 3000, //Toast's duration.
      });
    },
    emitSetStreamingButtonLoadingToFalse() {
      this.$emit("setStreamingButtonLoadingToFalse");
    },
    emitSetIsStreamingToFalse() {
      this.$emit("setIsStreamingToFalse");
    },
    SetPresetSelected(preset) {      
    
     
      this.$store.dispatch("database/SetOnDatabase", {
        path: "mordor-sites/" + this.Site[".key"] + "/camcontrol/presetSelected/",
        object: preset,
      });
      if(this.lastModifiedPreset.presetSelected != '' && 
          this.lastModifiedPreset.presetSelected != undefined && 
          this.lastModifiedPreset.presetSelected != this.presetSelected){
        
        this.SetPresetHistory()
      }
    },
    GetPresetSelected() {
      this.$store.dispatch("database/GetFromDatabase", {
        path:
          "mordor-sites/" + this.Site[".key"] + "/camcontrol/presetSelected/",
        returnSnapshotVal: true,
      }).then((el) => {        
        this.presetSelected = el;
      });
    },
    ListenPresetSelected() {      
      let ref = firebase.database().ref("mordor-sites/" + this.Site[".key"] + "/camcontrol/presetSelected/")
      ref.on("value", (snapshot) => {
        this.presetSelected = snapshot.val();        
      })
    },
    SetPresetHistory() {
      const now = new Date()
      const day = now.getDate().toString().padStart(2, "0")      
      const month = now.getMonth().toString().padStart(2, "0")      
      const year = now.getFullYear().toString().padStart(2, "0")      
      const presetHistory ={
        presetSelected: this.presetSelected,       
        user: this.GetCurrentUser.name,
        email: this.GetCurrentUser.email,        
        date: now.toLocaleDateString("pt-BR"),
        hour: now.toLocaleTimeString("pt-BR"),
        company: this.GetCurrentUser.company.toUpperCase(),
      }
      this.$store.dispatch("database/SetOnDatabase", {
        path:
        "mordor-history/" + this.Site[".key"] + "/presetHistory/"+year+"/"+month+"/"+day+"/"+now.getTime().toString()+"/",
        object: presetHistory
      }).then(()=>{
        this.SetPresetLastModified(presetHistory)        
      })      
    },
    SetPresetLastModified(presetHistory){
      this.$store.dispatch("database/SetOnDatabase", {
        path:
        "mordor-sites/" + this.Site[".key"] + "/camcontrol/presetLastModified/",
        object: presetHistory
      })
      },
    downloadImage() {     
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const blob = xhr.response;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = new Date().getTime() + ".jpg";
        link.click();
        URL.revokeObjectURL(link.href);
      };
      xhr.open("GET", this.GetImageUrl);
      xhr.send();
    
    },
  },
  
  created() {    
    this.GetMordorStatusImage();
    this.ListenPresetSelected();
   // this.LoadPresets();
    this.streamingData.email = this.GetCurrentUser.email;
    this.streamingData.user = this.GetCurrentUser.name;
    this.GetPresetSelected(); 
    this.irMode=localStorage.getItem("irMode")==="true"?true:false;
    setTimeout(() => {
      this.GetImageUrlCarousel();
    }, 800);
  },
  beforeDestroy() {
    if (this.IsStreaming) {
      this.CloseStreaming();
    }
  },
};
</script>
