<template>
  <!-- Information on the side of the camera -->
  <div class="column is-centered">
     <b-dropdown
      expanded
      aria-role="list"
      :scrollable="true"
      :max-height="300"
      v-model="presetSelected"
    >

      <template #trigger="{ active }" expanded>
        <b-button          
          expanded
          :label="$t('mordor.infoPreset') + presetSelected + ' )'"
          type="is-link"
          class="presetdropdown"
          outlined
          icon-pack="mdi"
          :icon-right="active ? 'menu-up' : 'menu-down'"        
        />
      </template>
      <!-- objetos a iterar: -->
      <b-dropdown-item
        v-for="p in presets"
        :key="p"
        :value="p"
        aria-role="listitem"
        >Preset {{ p }}
      </b-dropdown-item>
      <!-- fim da iteração -->
    </b-dropdown>
    <div
      class="infocard has-background-white-ter is-link is-outlined my-5 py-5 px-4"
    >
    
      <p class="is-size-5 has-text-left has-text-weight-semibold">Status</p>
      <p class="is-size-5 has-text-left">{{ Site.status.text }}</p>
      

      <p class="is-size-5 has-text-left has-text-weight-semibold mt-4">
        {{ $t("mordor.infoLocation") }}
      </p>
      <p class="is-size-5 has-text-left">{{ GetSiteLocation }} - {{  presetLocation }}</p>

      <p class="is-size-5 has-text-left has-text-weight-semibold mt-4">
        {{ $t("mordor.infoStreamConsumed") }}
      </p>
      <p class="is-size-5 has-text-left">
        {{ streamingMinutesConsumedInMiliseconds }}/{{ streamTimeLimit }}min
        <br />
        {{ streamingTimePercentageUsed }}% {{ $t("mordor.infoConsumed") }}
      </p>

      <p class="is-size-5 has-text-left has-text-weight-semibold mt-4">
        {{ $t("mordor.infoLastImgReq") }}
      </p>
      <p class="is-size-5 has-text-left">
        {{ new Date(Site.status.requestedFrame).toLocaleString("pt-BR") }}
      </p>

      <p class="is-size-5 has-text-left has-text-weight-semibold mt-4">
        {{ $t("mordor.infoLastImgReceived") }}
      </p>
      <p class="is-size-5 has-text-left">
        {{ new Date(Site.status.frameSentFromMordor).toLocaleString("pt-BR") }}
      </p>

      <p class="is-size-5 has-text-left has-text-weight-semibold mt-4">
        {{ $t("mordor.infoLastPreset") }}
      </p>
      <p class="is-size-5 has-text-left">{{ lastModifiedPreset.date}},{{ lastModifiedPreset.hour }} por {{ lastModifiedPreset.user}} ({{ lastModifiedPreset.company }})</p>
    </div>

    <div class="is-flex is-flex-direction-row is-justify-content-center align-items-left">
      <b-switch
        v-model="irMode"
        size="is-small"
        type="is-success"
        class="mb-2 is-size-6"
        >{{ $t("mordor.infoIR") }}</b-switch
      >
    </div>

    <b-button
      expanded
      v-if="GetCurrentUser.permissions.mordorReqStream"
      @click="startStream()"
      :loading="IsStreamingButtonLoading"
      class="btnControl my-2"
      size="is-large"
      :icon-left="!IsStreaming ? 'play' : 'stop'"
      :type="!IsStreaming ? 'is-link' : 'is-danger'"
      outlined
    >
      <span v-if="!IsStreaming">{{ $t("mordor.infoOpenStream") }}</span>
      <span v-else>{{ $t("mordor.infoCloseStream") }}</span>
    </b-button>

    <b-button
      expanded
      v-if="GetCurrentUser.permissions.mordorReqFrame"
      @click="reqFrame()"
      :loading="IsRequestButtonLoading"
      icon-left="image"
      type="is-link"
      size="is-large"
      class="button btnControl my-2"
      outlined
      >
      {{ $t("mordor.infoRequestFrame") }}
    </b-button>
    <b-button
      expanded
      @click="downloadImage"
      icon-left="download"
      type="is-link"
      size="is-large"
      class="button btnControl my-2"
      outlined
      >
      {{ $t("mordor.infoDownloadImg") }}
    </b-button>
  </div>

  <!-- buttons component goes here -->
</template>

<style lang="scss" scoped>
  .presetdropdown {
    border-radius: 6px;
    font-size: 1.8vh;
    margin: auto;
  }
  .infocard {
    min-height: fit-content;
    border-radius: 5%;
    margin: auto;
    outline: hsl(0, 0%, 86%) 1px solid;
  }

  .btnControl {
    border-radius: 6px;
    font-size: 1.8vh;
    margin: auto;
  }
  p {
    line-height: 1.5rem;
  }
</style>

<script>
import firebase from "../../../firebase.config.js";

export default {
  name: "GridCameraInfo",
  data() {
    return {
      IsRequestButtonLoading: false,
      streamTimeUsed: 0,
      streamTimeLimit: 480,
      streamTimeUsedPercent: 0,
      IsStreaming: false,
      IsStreamingButtonLoading: false,
      irMode: false,
      presetLocation: "",

      today: new Date(Date.now())
        .toLocaleDateString("pt-br", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/"),

      site: "",
      lastModifiedPreset:''
      
    };
  },
  props: {
    Site: Object,
    presets: Array,
    presetSelected: Number,
    
  },
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetIr() {
      return this.irMode;
    },
    GetSites() {
      return this.$store.getters["database/GetSites"];
    },
    GetMinutesFromSeconds() {
      const currentDate = new Date()
        .toLocaleDateString("pt-br", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/");
      const currentYear = currentDate[2];
      const currentMonth = currentDate[1];
      const secondsStreamedObject =
        this.Site.secondsStreamed[currentYear][currentMonth];
      let totalSecondsStreamed = 0;
      for (let item in secondsStreamedObject) {
        totalSecondsStreamed += secondsStreamedObject[item];
      }
      const time = {};
      const maxStreamingTime = 28800;
      time.percentageUsed = (totalSecondsStreamed / maxStreamingTime) * 100;
      time.hours = Math.floor(totalSecondsStreamed / 3600);
      time.minutes = Math.floor((totalSecondsStreamed % 3600) / 60);
      time.seconds = Math.floor((totalSecondsStreamed % 3600) % 60);
      return time;
    },
    streamingMinutesConsumedInMiliseconds() {
      return this.MilisecondsConverter(this.streamTimeUsed);
    },
    streamingTimePercentageUsed() {
      return this.streamTimeUsed >= 60000
        ? (this.streamTimeUsed / 60000 / (this.streamTimeLimit / 100))
            .toFixed(2)
            .replace(".", ",")
        : 0;
    },
    GetSiteLocation() {
      const locations = {
        "vale-teste": "Cordao Nova Vista",
        vale2: "Doutor",
        vale3: "Doutor",
        "teste-pqtec": "Pq. Tec.",
      };
      return locations[this.site.alias];
    },
   
  },
  watch: {
    GetIr: {
      handler() {
        // save in local storage
        localStorage.setItem("irMode", this.irMode);        
        this.GetMordorStatusImage();
      },
    },
    presetSelected:{
      handler(){
        this.SetPresetSelected();   
        this.GetLastPresetModified()        
        this.GetPresetLocation();            
        
      }
    }
  },
  created() {
    this.GetLastPresetModified();
    this.QuoteOfStreaming();
    //this.ListenPresetSelected();
    setTimeout(() => {
      this.GetPresetLocation();            
    }, 500);
    this.irMode=localStorage.getItem("irMode")==="true"?true:false;

  },
  methods: {
    GetPresetLocation() {
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "mordor-sites/" + this.Site[".key"] + "/camcontrol/presets/" + this.presetSelected + "/presetRegion",
            returnSnapshotVal: true,
        })
        .then((el) => {
          this.presetLocation = el;          
        });
       
    },
    downloadImage() {     
      this.$emit("downloadImage");
      // const xhr = new XMLHttpRequest();
      // xhr.responseType = "blob";
      // xhr.onload = function () {
      //   const blob = xhr.response;
      //   const link = document.createElement("a");
      //   link.href = URL.createObjectURL(blob);
      //   link.download = new Date().getTime() + ".jpg";
      //   link.click();
      //   URL.revokeObjectURL(link.href);
      // };
      // xhr.open("GET", this.ImageUrl);
      // xhr.send();
    
    },
    async GetMordorStatusImage() {
      try {
        const imageType = this.irMode ? "IR" : "TV";

        this.LastImageTimestamp = this.Site.status.frameSentFromMordor;
        this.isFrameLoading = true;
        const response = await this.$store.dispatch(
          `storage/GetMordorDemandImageDownloadLink${imageType}`,
          this.Site[".key"]
        );
        this.ImageUrl = response;
      } catch (error) {
        console.log(error);
      } finally {
        this.isFrameLoading = false;
      }
    },
    startStream() {
      this.$emit("playVideo");
      this.IsStreaming = !this.IsStreaming;
      if (this.IsStreaming) {
        this.$refs.playCam.RequestStreaming();
        this.IsStreamingButtonLoading = true;
      } else {
        this.$refs.playCam.CloseStreaming();
      }
    },
    setStreamingButtonLoadingToFalse() {
      this.IsStreamingButtonLoading = false;
    },
    async RequestCameraFrame() {
      this.IsRequestButtonLoading = true;
      try {
        this.$store.dispatch("database/SetOnDatabase", {
          path: `mordor-sites/${this.Site[".key"]}/status/requestedFrame`,
          object: Date.now(),
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.IsRequestButtonLoading = false;
      }
    },
    MilisecondsConverter(miliseconds) {
      let time = "";
      if (miliseconds < 60000) {
        time = (miliseconds / 1000).toFixed(1) + "s";
      }
      if (miliseconds >= 60000) {
        time = (miliseconds / 60000).toFixed(0) + "min";
      }
      return time;
    },
    QuoteOfStreaming() {
      this.streamTimeUsed = 0;
      this.site =
        this.GetCurrentUser.activeFlag == "mrd-03-vale"
          ? this.GetSites[1]
          : this.GetSites[0];
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "mordor-history/" +
            this.site[".key"] +
            "/streamingUsed/" +
            this.today[2] +
            "/" +
            this.today[1],
          returnSnapshotVal: true,
        })
        .then((el) => {
          Object.keys(el).forEach((key) => {
            this.streamTimeUsed += el[key].endTime - el[key].startTime;
          });
        });
      this.streamTimeUsedPercent =
        this.streamTimeUsed >= 60000
          ? (this.streamTimeUsed / 60000 / (this.streamTimeLimit / 100))
              .toFixed(2)
              .replace(".", ",")
          : 0;
    },
    SetPresetSelected() {
      
      
      this.$store.dispatch("database/SetOnDatabase", {
        path:
        "mordor-sites/" + this.Site[".key"] + "/camcontrol/presetSelected/",
        object: parseInt(this.presetSelected)
      })
      if(this.lastModifiedPreset.presetSelected != '' && 
          this.lastModifiedPreset.presetSelected != undefined && 
          this.lastModifiedPreset.presetSelected != this.presetSelected){        
          this.SetPresetHistory()
      }
    },
    ListenPresetSelected() {
      let ref = firebase.database().ref("mordor-sites/" + this.Site[".key"] + "/camcontrol/presetSelected/")
      ref.on("value", (snapshot) => {
        
        this.presetSelected = snapshot.val();
      })
    },
    SetPresetHistory() {
      const now = new Date()
      const day = now.getDate().toString().padStart(2, "0")      
      const month = now.getMonth().toString().padStart(2, "0")      
      const year = now.getFullYear().toString().padStart(2, "0")      
      const presetHistory ={
        presetSelected: this.presetSelected,       
        user: this.GetCurrentUser.name,
        email: this.GetCurrentUser.email,        
        date: now.toLocaleDateString("pt-BR"),
        hour: now.toLocaleTimeString("pt-BR"),
        company: this.GetCurrentUser.company.toUpperCase(),
      }
      this.$store.dispatch("database/SetOnDatabase", {
        path:
        "mordor-history/" + this.Site[".key"] + "/presetHistory/"+year+"/"+month+"/"+day+"/"+now.getTime().toString()+"/",
        object: presetHistory
      }).then(()=>{
        this.SetPresetLastModified(presetHistory)        
      })      
     },
    SetPresetLastModified(presetHistory){
      this.$store.dispatch("database/SetOnDatabase", {
        path:
        "mordor-sites/" + this.Site[".key"] + "/camcontrol/presetLastModified/",
        object: presetHistory
      })
      },
    GetLastPresetModified(){
      this.$store.dispatch("database/GetFromDatabase", {
        path:"mordor-sites/" + this.Site[".key"] + "/camcontrol/presetLastModified/",
        returnSnapshotVal: true
      }).then((el)=>{
        this.lastModifiedPreset = el
      })
     }
  },
  

};
</script>
