<template>
  <div class="column is-vcentered is-centered is-6 my-0">
    <div v-if="IsStreaming">
      <BasePlayer :options="videoOptions" :videoSource="videoSource" />
    </div>
    <div v-else style="position: relative">
      <!-- <b-loading :is-full-page="false" v-model="isFrameLoading" :can-cancel="false"></b-loading> -->
      <figure class="image is-16by9" oncontextmenu="return false;">
        <img :src="GetImageUrl" />
      </figure>

      <div class="columns is-centered is-vcentered">
        <div class="column has-text-centered is-one-third">
          <b-dropdown
            expanded
            aria-role="list"
            :scrollable="true"
            :max-height="120"
            v-model="presetSelected"
          >
            <template #trigger="{ active }" expanded>
              <b-button
                expanded
                :label="$t('mordor.infoPreset') + presetSelected + ' )'"
                type="is-link"
                class="presetdropdown"
                outlined
                icon-pack="mdi"
                :icon-right="active ? 'menu-up' : 'menu-down'"
              />
            </template>
            <!-- objetos a iterar: -->
            <b-dropdown-item 
            v-for="p in presets" 
            :key="p" 
            :value="p"
            aria-role="listitem"
              >
              Preset {{ p }}
              </b-dropdown-item
            >
            <!-- fim da iteração -->
          </b-dropdown>          
        </div>
        <div class="column has-text-centered is-one-third">
          <b-button
            expanded
            @click="downloadImage()"
            icon-left="download"
            type="is-link"
            size="is-large"
            class="button btnControl my-2"
            outlined
            >{{ $t("mordor.infoDownloadImg") }}
          </b-button>
        </div>
        <div class="column has-text-centered">
          <b-switch
            v-model="irMode"
            size="is-small"
            type="is-success"
            class="is-vcentered is-size-6 has-text-centered"
            >{{ $t("mordor.infoIR") }}</b-switch
          >
        </div>
      </div>
    </div>

    <br />
  </div>
</template>
<style lang="scss" scoped>
  .presetdropdown {
    border-radius: 6px;
    font-size: 1.8vh;
    margin: auto;
  }

  .btnControl {
    border-radius: 6px;
    font-size: 1.8vh;
    margin: auto;
  }
  .btn-high-left {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .has-vertically-aligned-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .has-side-margins {
    margin-left: 3.25rem;
 }
</style>
<script>
import BasePlayer from "@/components/home/BasePlayer.vue";
import firebase from "../../../firebase.config.js";
export default {
  name: "MultiCam",
  data() {
    return {
      isListening: false,
      isFrameLoading: true,
      IsStreamingButtonLoading: false,
      isFullPage: false,
      ImageUrl: "",
      IsStreaming: false,
      LastImageTimestamp: 0,
      videoOptions: {
        autoplay: true,
        controls: true,
        preload: true,
        inactivityTimeout: 0,
        liveui: true,
        fluid: true,
        sources: null,
      },
      videoSource: "",
      streamingData: {
        email: "",
        user: "",
        startTime: "",
        endTime: "",
        totalTime: "",
      },
      irMode: false,
      presets: [],
      presetSelected:'',
      lastModifiedPreset:'',
      flag:""
    };
  },
  props: {
    Site: Object,
  },
  components: {
    BasePlayer,
  },
  computed: {
    GetImageUrl() {
      return this.ImageUrl;
    },
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetIr: {
      handler() {
        this.GetMordorStatusImage();
      },
    },
  },
  watch: {
    Site: {
      handler(site) {
        if (
          !site.kinesisVideoService &&
          this.LastImageTimestamp !== site.status.frameSentFromMordor
        ) {
          this.LastImageTimestamp = site.status.frameSentFromMordor;
          this.GetMordorStatusImage();
        }
      },
    },
    
    presetSelected:{
      handler(){
        this.SetPresetSelected();
        this.GetLastPresetModified();
      }
    }
  },

  created() {
    // this.LoadPresets();
    this.GetMordorStatusImage();
    this.GetPresets()
    this.flag = this.Site.alias == "vale-teste" ? "mordor-vale-1" : "mordor-vale-3" 
    this.GetLastPresetModified();
    this.streamingData.email = this.GetCurrentUser.email;
    this.streamingData.user = this.GetCurrentUser.name;
    
  },
  methods: {
    downloadImage() {     
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const blob = xhr.response;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = new Date().getTime() + ".jpg";
        link.click();
        URL.revokeObjectURL(link.href);
      };
      xhr.open("GET", this.ImageUrl);
      xhr.send();
    
    },
  
    GetMordorStatusImage() {
      this.LastImageTimestamp = this.Site.status.frameSentFromMordor;
      this.isFrameLoading = true;
      if (this.irMode) {
        this.$store
          .dispatch(
            "storage/GetMordorDemandImageDownloadLinkIR",
            this.Site[".key"]
          )
          .then((snapshot) => {
            this.ImageUrl = snapshot;
            this.isFrameLoading = false;
          });
      } else {
        this.$store
          .dispatch(
            "storage/GetMordorDemandImageDownloadLinkTV",
            this.Site[".key"]
          )
          .then((snapshot) => {
            this.ImageUrl = snapshot;
            this.isFrameLoading = false;
          });
      }
    },
    ToggleLoadings(toggle) {
      this.isFrameLoading = toggle;
      this.IsStreamingButtonLoading = toggle;
    },
    LaunchToast(message, type) {
      this.$buefy.toast.open({
        //Displays toast with given properties below.
        message: message, //Message to display.
        type: type, //Toast's color.
        position: "is-bottom", //Toast's position.
        duration: 3000, //Toast's duration.
      });
    },
    LoadPresets(flag) {
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "mordor-sites/" + flag + "/camcontrol/presets/",
          returnSnapshotVal: true,
        })
        .then((el) => {
          this.presets= Object.keys(el);
        });
    },
    GetPresets() {
      if(this.Site.alias=='vale-teste'){
        this.LoadPresets("mordor-vale-1")
        this.ListenPresetSelected("mordor-vale-1")
      }
      if(this.Site.alias=='vale3'){
       this.LoadPresets("mordor-vale-3")
       this.ListenPresetSelected("mordor-vale-3")
      }
      else{
        this.LoadPresets(this.GetCurretUser.activeFlag)
       this.ListenPresetSelected(this.GetCurretUser.activeFlag)
      }      
    },
    ListenPresetSelected(flag) {
      let ref = firebase.database().ref("mordor-sites/" + flag + "/camcontrol/presetSelected/")
      ref.on("value", (snapshot) => {
        this.presetSelected = snapshot.val();
      })
    }, 
    SetPresetSelected() {
      this.$store.dispatch("database/SetOnDatabase", {
        path:
        "mordor-sites/" +this.Site[".key"]+ "/camcontrol/presetSelected/",
        object: parseInt(this.presetSelected)
      })
      
      if(this.lastModifiedPreset.presetSelected != '' && 
          this.lastModifiedPreset.presetSelected != undefined && 
          this.lastModifiedPreset.presetSelected != this.presetSelected){
          this.SetPresetHistory()
      }
    },
    SetPresetHistory() {
      
      const now = new Date()
      const day = now.getDate().toString().padStart(2, "0")      
      const month = now.getMonth().toString().padStart(2, "0")      
      const year = now.getFullYear().toString().padStart(2, "0")      
      const presetHistory ={
        presetSelected: this.presetSelected,       
        user: this.GetCurrentUser.name,
        email: this.GetCurrentUser.email,        
        date: now.toLocaleDateString("pt-BR"),
        hour: now.toLocaleTimeString("pt-BR"),
        company: this.GetCurrentUser.company.toUpperCase(),
      }
      this.$store.dispatch("database/SetOnDatabase", {
        path:
        "mordor-history/" + this.flag + "/presetHistory/"+year+"/"+month+"/"+day+"/"+now.getTime().toString(),
        object: presetHistory
      }).then(()=>{
        this.SetPresetLastModified(presetHistory)        
      })      
     },
    SetPresetLastModified(presetHistory){
      
      this.$store.dispatch("database/SetOnDatabase", {
        path:
          "mordor-sites/" + this.flag + "/camcontrol/presetLastModified/",
          object: presetHistory
        })
      },
    GetLastPresetModified(){
      this.flag = this.Site.alias == "vale-teste" ? "mordor-vale-1" : "mordor-vale-3" 
      this.$store.dispatch("database/GetFromDatabase", {
        path:"mordor-sites/" + this.flag + "/camcontrol/presetLastModified/",
        returnSnapshotVal: true
      }).then((el)=>{
        this.lastModifiedPreset = el
      })
    }
  }
};
</script>
