<template>
  <div>
    <TheNavbar />

    <div class="box principal mr-4 ml-4">
      <div class="btns">
        <b-button
          type="is-danger"
          @click="isImageModalActive = true"
          icon-pack="mdi"
          icon-left="circle-slice-8"
          rounded
          class="mr-4 ml-4 btn is-uppercase"
        >
        {{ $t("mordor.btnRec") }}</b-button
        >
        <span v-if="GetSites.length > 1">
          <b-button
            type="is-info"
            size="is-small"
            v-if="showMultiCam"
            @click="showCams"
            icon-left="desktop"
            rounded
            class="mr-4 ml-4 btn is-uppercase"
            >Cam</b-button
          >
          <b-button
            type="is-info"
            size="is-small"
            v-else
            @click="showCams"
            icon-left="columns"
            rounded
            class="mr-4 ml-4 btn is-uppercase"
            >Multicam</b-button
          >
        </span>

        <b-button
          type="is-dark"
          size="is-small"
          icon-left="tachometer-alt"
          rounded
          class="mr-4 ml-4 btn is-uppercase"
          @click="showDashboard"
          >Dashboard</b-button
        >
      </div>

      <hr />

      <div v-if="showMordorDashboard">
        <MordorDashboard />
      </div>

      <div v-else>
        <div
          v-if="showMultiCam"
          class="columns is-centered is-multiline has-background-white-bis"
        >
          <template v-for="site in GetSites">                    
            <MultiCam
            v-on:downloadImage="downloadImage"
             :Site="site" :key="'gc' + site.id"/>
          </template>
        </div>
        
        

        <div
          class="columns is-centered is-multiline has-background-white-bis"
          v-if="!showMultiCam"
        >
          <template v-if="GetCurrentUser.activeFlag == 'mrd-03-vale'">
            <GridCamera
              ref="playCam"
              :presets="presets"
              :presetSelected="presetSelected"
              :Site="GetSites[1]"
              :key="'gc' + GetSites[1].id"
            />
            <GridCameraInfo
              v-on:downloadImage="downloadImage"
              v-on:playVideo="startStream"
            :presets="presets"
            :presetSelected="presetSelected"
              ref="infoCam"
              :Site="GetSites[1]"
              :key="'gci' + GetSites[1].id"
            />
          </template>

          <template
            v-if="
              GetCurrentUser.activeFlag == 'mrd-01-vale' ||
              GetCurrentUser.activeFlag == 'mrd-01-pqtec'
            "
          >
            <GridCamera
              ref="playCam"
              :presets="presets"
              :presetSelected="presetSelected"
              :Site="GetSites[0]"
              :key="'gc' + GetSites[0].id"
              @setStreamingButtonLoadingToFalse="
                setStreamingButtonLoadingToFalse
              "
              @setIsStreamingToFalse="setIsStreamingToFalse"
            />
            <GridCameraInfo
              v-on:downloadImage="downloadImage"
              v-on:playVideo="startStream"
              ref="infoCam"
              :Site="GetSites[0]"
              :presets="presets"
              :presetSelected="presetSelected"
              :key="'gci' + GetSites[0].id"
            />
          </template>

          <!-- <b-button
              expanded
              v-if="GetCurrentUser.permissions.mordorReqStream"
              @click="startStream()"
              :loading="IsStreamingButtonLoading"
              class="btnControl mr-2 column"
              size="is-large"
              :icon-left="!IsStreaming ? 'play' : 'stop'"
              :type="!IsStreaming ? 'is-link' : 'is-danger'"
              outlined
            >
              <span v-if="!IsStreaming">ABRIR STREAMING</span>
              <span v-else>FECHAR STREAMING</span>
            </b-button>

            <b-button
              expanded
              v-if="GetCurrentUser.permissions.mordorReqFrame"
              @click="reqFrame()"
              :loading="IsRequestButtonLoading"
              icon-left="image"
              type="is-link"
              size="is-large"
              class="button column btnControl"
              outlined
              >REQUISITAR FRAME</b-button
            >
         -->
        </div>
      </div>

      <b-modal v-model="isImageModalActive">
        <RequestRecording />
      </b-modal>
    </div>

    <footer
      v-if="GetCurrentUser.activeFlag.toLowerCase().includes('vale')"
      class="box mr-4 ml-4 mb-2 footerLogo"
    >
      <b-field grouped group-multiline position="is-centered">
        <b-field>
          <img id="logo" src="@/assets/vale-logo-8.png" alt="vale" />
        </b-field>
        <b-field>
          <p class="control logoLegend is-uppercase">
            Centro de Monitoramento Geotécnico - Corredor Sudeste
          </p>
        </b-field>
      </b-field>
    </footer>

    <footer v-else class="box mr-4 ml-4 mb-2 footerLogo">
      <b-field grouped group-multiline position="is-centered">
        <b-field>
          <img id="logo" src="@/assets/harpia-color.png" alt="Altave" />
        </b-field>
      </b-field>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
  hr {
    background-color: lightgray;
    height: 0.2vh;
  }

  .btn {
    width: 28vh;
    border-radius: 6px;
    font-weight: bolder;
    font-size: 1.8vh;
  }

  .btnFooter {
    width: 100%;
    margin-bottom: -10%;
  }

  .btnControl {
    border-radius: 6px;
    font-weight: bolder;
    font-size: 1.8vh;
    width: 90%;
  }

  b-field {
    align-items: center;
  }

  .footerLogo {
    display: block;
    height: 9vh;
    margin-top: -2vh;
  }

  .logoLegend {
    margin: 1vh;
    margin-top: 1.5vh;
    color: #747678c9;
    font-size: 1.8vh;
    font-weight: 600;
  }

  .principal {
    min-height: 78vh;
  }

  #logo {
    width: 10vh;
    margin-right: 0vh;
  }

  .has-custom-margins {
    margin: 1em;
  }

  .column.has-vertically-aligned-content {
    flex-direction: column;
    justify-content: center;
  }

  .is-full-parent-height {
    height: 50%;
    width: 50%;
  }

  .has-right-border-colored {
    border-right: 2px outset;
  }

  @media (min-device-width: 640px) and (max-device-width: 812px) and (orientation: landscape) {
    .is-fullscreen {
      position: fixed;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 87%;
      width: auto;
      height: 87vh;
    }
 }
</style>
<script>
import TheNavbar from "@/components/global/TheNavbar.vue";
import GridCamera from "@/components/mordor/GridCamera.vue";
import GridCameraInfo from "@/components/mordor/GridCameraInfo.vue";
import MultiCam from "@/components/mordor/MultiCam.vue";
import RequestRecording from "@/components/mordor/RequestRecording.vue";
import MordorDashboard from "@/components/mordor/MordorDashboard.vue";
import firebase from "../../../firebase.config.js";

export default {
  name: "TheGrid",
  components: {
    TheNavbar,
    GridCamera,
    GridCameraInfo,
    MultiCam,
    RequestRecording,
    MordorDashboard,
  },
  data() {
    return {
      showMultiCam: false,
      isImageModalActive: false,
      showMordorDashboard: false,
      streamingPlayer: true,
      IsRequestButtonLoading: false,
      IsStreaming: false,
      IsStreamingButtonLoading: false,
      presets: [],
      presetSelected: ""
    };
  },
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetSites() {
      return this.$store.getters["database/GetSites"];
    },
    fixStatus() {
      if (0 > parseInt(this.GetSites[0].streaming)) {
        this.$store.dispatch("database/SetOnDisconnectHandler", {
          method: "set",
          path: "mordor-sites/mordor-vale-1/streaming",
          object: 0,
        });
      }
      if (0 > parseInt(this.GetSites[1].streaming)) {
        this.$store.dispatch("database/SetOnDisconnectHandler", {
          method: "set",
          path: "mordor-sites/mordor-vale-2/streaming",
          object: 0,
        });
      }

      return "";
    },
  },
  methods: {
    LoadPresets() {
      let flag = this.GetCurrentUser.activeFlag;      
      if (flag == "mrd-01-vale") {
        flag = "mordor-vale-1";
      } else if (flag == "mrd-03-vale") {
        flag = "mordor-vale-3";
      }
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "mordor-sites/" + flag + "/camcontrol/presets/",
          returnSnapshotVal: true,
        })
        .then((el) => {
          this.presets = Object.keys(el);
        });
    },
    reqFrame() {
      this.$refs.infoCam.RequestCameraFrame();
    },
    startStream() {
      this.IsStreaming = !this.IsStreaming;

      if (this.IsStreaming) {
        this.$refs.playCam.RequestStreaming();
        this.IsStreamingButtonLoading = true;
      } else {
        this.$refs.playCam.CloseStreaming();
      }
    },
    showCams() {
      this.showMultiCam = !this.showMultiCam;
      this.showMordorDashboard = false;
      if (this.IsStreaming) {
        this.$refs.playCam.SetStreaming();
        this.IsStreaming = false;
      }
    },
    showDashboard() {
      this.showMordorDashboard = !this.showMordorDashboard;
      if (this.IsStreaming) {
        this.$refs.playCam.SetStreaming();
        this.IsStreaming = false;
      }
    },
    setStreamingButtonLoadingToFalse() {
      this.IsStreamingButtonLoading = false;
    },
    setIsStreamingToFalse() {
      this.IsStreaming = false;
    },
    GetPresetSelected() {
      let flag = this.GetCurrentUser.activeFlag;      
      if (flag == "mrd-01-vale") {
        flag = "mordor-vale-1";
      } else if (flag == "mrd-03-vale") {
        flag = "mordor-vale-3";
      }
      this.$store.dispatch("database/GetFromDatabase", {
        path:
          "mordor-sites/" + flag +
          "/camcontrol/presetSelected/",
        returnSnapshotVal: true,
      }).then((el) => {        
        this.presetSelected = el;
      });
    },
    ListenPresetSelected() {
      let flag = this.GetCurrentUser.activeFlag;      
      if (flag == "mrd-01-vale") {
        flag = "mordor-vale-1";
      } else if (flag == "mrd-03-vale") {
        flag = "mordor-vale-3";
      }
      let ref = firebase.database().ref("mordor-sites/" + flag+ "/camcontrol/presetSelected/")      
      ref.on("value", (snapshot) => {
        this.presetSelected = snapshot.val();        
      })
    },
    downloadImage(){    
      this.$refs.playCam.downloadImage();
    }
   
  },
  watch: {
    GetCurrentUser: {
      handler() {
        this.$store.dispatch("database/unbindSites");
        this.$store.dispatch(
          "database/bindSites",
          this.GetCurrentUser.activeFlag
        );
        if (this.IsStreaming) {
          this.$refs.playCam.CloseStreaming();
        }
        this.showMultiCam = false;
        this.IsStreaming = false;
        this.GetPresetSelected();
        this.LoadPresets();    
        this.ListenPresetSelected();
      },
      deep: true, //Watch for changes on the entire user object, not just its first level.
    }
  },
  created() {
    this.$store.dispatch("database/bindSites", this.GetCurrentUser.activeFlag);
    this.GetPresetSelected();
    this.LoadPresets();    
    this.ListenPresetSelected();
  },
  beforeDestroy() {
    if (this.IsStreaming) {
      this.$refs.playCam.CloseStreaming();
    }
    this.$store.dispatch("database/unbindSites");
  },
};
</script>
