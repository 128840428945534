<template>
  <div>
    <div class="columns my-1 mx-1">
      <div class="column card my-4 mx-2">
        <p class="title">
          {{ $t("mordor.dashSystem") }}
        </p>
        <p
          class="is-size-4"
          :class="systemStatus === 'Disponível' ? 'statusOn' : 'statusOff'"
        >
          {{ systemStatus }}
        </p>

        <p class="is-size-4 mt-3">
          <b>{{ $t("mordor.dashLoading") }}</b>
        </p>

        <p
          class="is-size-4"
          :class="
            availability === 'Sem disponibilidade' ? 'statusOff' : 'statusOn'
          "
        >
          {{ availability }}
        </p>

        <p class="is-size-5 my-5">
          {{ $t("mordor.dashLastVer") }} {{ lastVerificationSystem }}
        </p>
      </div>

      <div class="column card my-4 mx-2">
        <p class="title">Streaming</p>

        <div v-if="GetSites">
          <p class="is-size-2 has-text-weight-bold">
            {{ MilisecondsConverter(streamTimeUsed) }}/{{ streamTimeLimit }}min
          </p>

          <p class="is-size-3 has-text-weight-medium my-5">
            {{ streamTimeUsedPercent }}% {{ $t("mordor.dashPercentUsed") }}
          </p>
        </div>
        <p class="statusOff is-size-4" v-else>{{ $t("mordor.dashLoading") }}</p>
      </div>

      <div class="column card my-4 mx-2">
        <p class="title">{{ $t("mordor.dashLastView") }}</p>
        <b-table :data="watchings" class="my-table">
          <b-table-column label="Solicitante" v-slot="props">
            {{ props.row[1].user }}
          </b-table-column>
          <b-table-column label="Data" v-slot="props">
            {{ new Date(props.row[1].startTime).toLocaleDateString("pt-BR") }}
            <br />
            ({{
              new Date(props.row[1].startTime).toLocaleTimeString("pt-BR")
            }}
            - {{ new Date(props.row[1].endTime).toLocaleTimeString("pt-BR") }})
          </b-table-column>
          <b-table-column label="Total" v-slot="props">
            {{
              MilisecondsConverter(
                props.row[1].endTime - props.row[1].startTime
              )
            }}
          </b-table-column>
        </b-table>
      </div>
    </div>
    <div class="columns my-1 mx-1">
      <div class="column card my-4 mx-2">
        <p class="title">{{ $t("mordor.dashRecRequest") }}</p>
        <b-table
          :data="requestsRecordings"
          class="my-table"
          v-if="requestsRecordings.length"
        >
          <b-table-column label=" $t('mordor.dashRequester') " v-slot="props">
            {{ props.row[1].requester }}
          </b-table-column>
          <b-table-column
            label=" $t('mordor.dashDateRequest') "
            v-slot="props"
            field="created"
          >
            {{ new Date(props.row[1].created).toLocaleString() }}
          </b-table-column>
          <b-table-column
            label="$t('mordor.dashDateRequest')"
            v-slot="props"
            field="dateSelect"
          >
            {{ props.row[1].dateSelect }} <br />
            ({{ props.row[1].startHour.substring(0, 5) }} à
            {{ props.row[1].endHour.substring(0, 5) }})
          </b-table-column>
        </b-table>
        <div class="statusOff is-size-4" v-else>{{ $t("mordor.dashNoData") }}</div>
      </div>

      <div
        class="column card my-4 mx-2"
        v-if="GetCurrentUser.activeFlag.toLowerCase() != 'mrd-03-vale'"
      >
        <span class="title">Links</span>

        <ul class="separator">
          <li>
            <p class="is-size-4 has-text-weight-bold">{{ $t("mordor.dashReports") }}</p>
            <p
              class="mt-5 is-size-5"
              v-if="GetCurrentUser.activeFlag.match(/\d+/)[0] === '01'"
            >
              <a :href="GetSites[0].links.report" target="_blank">
                <b> {{ $t("mordor.dashDriveLink") }}</b>
              </a>
            </p>
            <p class="mt-5 is-size-5" v-else>
              <a :href="GetSites[1].links.report" target="_blank">
                <b> {{ $t("mordor.dashDriveLink") }}</b>
              </a>
            </p>
          </li>

          <li>
            <p class="is-size-4 mt-4 has-text-weight-bold">{{ $t("mordor.dashVideos") }}</p>
            <p
              class="mt-5 is-size-5"
              v-if="GetCurrentUser.activeFlag.match(/\d+/)[0] === '01'"
            >
              <a :href="GetSites[0].links.videos" target="_blank">
                <b>{{ $t("mordor.dashDriveLink") }}</b>
              </a>
            </p>
            <p class="mt-5 is-size-5" v-else>
              <a :href="GetSites[1].links.videos" target="_blank">
                <b>{{ $t("mordor.dashDriveLink") }}</b>
              </a>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scope>
.card {
  background-color: grayscale($color: #eceaea8a);
  height: 20rem;
}
.statusOn {
  color: #01d401;
  font-size: 300%;
  font-weight: 900;
}
.statusOff {
  color: #ca0000;
  font-size: 300%;
  font-weight: 900;
}
.my-table {
  height: 14rem;
  overflow-y: auto;
  text-align: left;
}

ul.separator li {
  padding: 0.5em 0;
  border-bottom: 1px solid #000;
  text-align: left;
}
</style>

<script>
export default {
  name: "MordorDashboard",
  data() {
    return {
      requestsRecordings: {},
      statusSystem: "",
      lastVerificationSystem: "--:--",
      streamTimeLimit: 480,
      streamTimeUsed: 0,
      streamTimeUsedPercent: 0,
      streamData: 0,
      systemStatus: "Carregando...",
      oldStreaming: 1,
      oldFrame: 1,
      oldFrameMordor: 1,
      today: new Date(Date.now())
        .toLocaleDateString("pt-br", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/"),
      watchings: [],
      site: "",
      availability: "0",
    };
  },
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetSites() {
      return this.$store.getters["database/GetSites"];
    },
  },
  methods: {
    FindRequests() {
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "mordor-request-recording/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/date-index",
        })
        .then((dates) => {
          Object.keys(dates.val()).forEach(() => {
            this.$store
              .dispatch("database/GetFromDatabase", {
                path:
                  "mordor-request-recording/" +
                  this.GetCurrentUser.activeFlag.toLowerCase() +
                  "/" +
                  this.today[2] +
                  "/" +
                  this.today[1],
                returnSnapshotVal: true,
              })
              .then((result) => {
                result
                  ? (this.requestsRecordings = Object.entries(result).reverse())
                  : ""; //Arranges descending and transforms into an array.
              });
          });
        });
    },
    FindLastWatches() {
      // this.site= this.GetCurrentUser.activeFlag == 'mrd-02-vale'?this.GetSites[1]:this.GetSites[0]
      this.site =
        this.GetCurrentUser.activeFlag == "mrd-03-vale"
          ? this.GetSites[1]
          : this.GetSites[0];
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "mordor-history/" +
            this.site[".key"] +
            "/streamingUsed/" +
            this.today[2] +
            "/" +
            this.today[1],
          returnSnapshotVal: true,
        })
        .then((el) => {
          this.streamData = el;
          // if(this.watchings!=null){
          this.watchings = Object.entries(el).reverse(); //Arranges descending and transforms into an array.
          // }
        });
    },
    MilisecondsConverter(miliseconds) {
      let time = "";
      if (miliseconds < 60000) {
        time = (miliseconds / 1000).toFixed(1) + "s";
      }
      if (miliseconds >= 60000) {
        time = (miliseconds / 60000).toFixed(0) + "min";
      }
      return time;
    },
    verifyStatus() {
      this.site =
        this.GetCurrentUser.activeFlag == "mrd-03-vale"
          ? this.GetSites[1]
          : this.GetSites[0];
      this.verifyAvailability();
      if (
        this.site.status.frameSentFromMordor < Date.now() - 38000 ||
        this.oldStreaming === this.site.status.streamingOnDemand ||
        this.oldFrame === this.site.status.frameOnDemand ||
        this.oldFrameMordor === this.site.status.frameSentFromMordor
      ) {
        this.systemStatus = "Indisponível";
      } else {
        this.oldStreaming = this.site.status.streamingOnDemand;
        this.oldFrame = this.site.status.frameOnDemand;
        this.oldFrameMordor = this.site.status.frameSentFromMordor;
        this.systemStatus = "Disponível";
      }
    },
    verifyStreamingQuote() {
      this.streamTimeUsed = 0;
      if (this.streamData) {
        Object.keys(this.streamData).forEach((key) => {
          this.streamTimeUsed +=
            this.streamData[key].endTime - this.streamData[key].startTime;
        });
      }
      this.streamTimeUsedPercent =
        this.streamTimeUsed >= 60000
          ? (this.streamTimeUsed / 60000 / (this.streamTimeLimit / 100))
              .toFixed(2)
              .replace(".", ",")
          : 0;
      this.lastVerificationSystem = new Date(Date.now()).toLocaleTimeString(
        "pt-BR",
        { hour: "2-digit", minute: "2-digit" }
      );
    },
    verifyAvailability() {
      this.availability = "carregando...";
      this.site =
        this.GetCurrentUser.activeFlag == "mrd-03-vale"
          ? this.GetSites[1]
          : this.GetSites[0];
      this.availability =
        this.site.availability?.current.availability || "Sem disponibilidade";
    },
  },
  mounted() {
    if (this.GetCurrentUser) {
      this.$store.dispatch(
        "database/bindSites",
        this.GetCurrentUser.activeFlag
      );
    }
  },
  created() {
    this.verifyAvailability();
    setInterval(this.verifyStreamingQuote, 5000);
    setInterval(this.FindRequests, 5000);
    setInterval(this.FindLastWatches, 5000);
    setInterval(this.verifyStatus, 33000);
    setInterval(this.verifyAvailability, 33000);
  },
  watch: {
    GetCurrentUser: {
      handler() {
        this.$store.dispatch("database/unbindSites");
        this.$store.dispatch(
          "database/bindSites",
          this.GetCurrentUser.activeFlag
        );
        this.requestsRecordings = "";
        this.lastVerificationSystem = "--:--";
        this.streamTimeUsed = 0;
        this.streamTimeUsedPercent = 0;
        this.watchings = "";
        // this.site= this.GetCurrentUser.activeFlag == 'mrd-02-vale'?this.GetSites[1]:this.GetSites[0]
        this.site =
          this.GetCurrentUser.activeFlag == "mrd-03-vale"
            ? this.GetSites[1]
            : this.GetSites[0];
        this.verifyStreamingQuote;
        this.verifyStatus;
        this.verifyAvailability;
      },
      deep: true, //Watch for changes on the entire user object, not just its first level.
    },
  },
};
</script>
